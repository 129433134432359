'use client';

import { Customer } from 'lib/bigcommerce/types/customer';
import { ResultJSON } from 'lib/result';
import { useEffect, useState } from 'react';
import useSWR, { Fetcher } from 'swr';

const fetcher: Fetcher<ResultJSON<Customer>, string> = (path) => fetch(path).then((res) => res.json());
export default function useUser() {
  const { data, mutate, error, isLoading } = useSWR("/api/user", fetcher);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized]);

  const loading = initialized ? isLoading : true;

  if (data?.isFailure) {
    console.log(data);
  }
  if (error) {
    console.log(error);
  }

  let user;
  if (!loading) {
    user = data?.value;
  }

  return {
    loading,
    loggedIn: !!(user?.email),
    user: user,
    mutate
  };
}
