import { ReadonlyURLSearchParams } from 'next/navigation';
import { SafeParseReturnType } from 'zod/lib/types';
import { Money } from './bigcommerce/types';
import { BASE_URL } from './constants';

export function absoluteUrl(urlString: string) {
  if (urlString.startsWith('http')) {
    return urlString;
  }

  const url = new URL(urlString, BASE_URL);
  return url.toString();
}

export function relativeUrl(urlString: string) {
  if (urlString.startsWith('/')) {
    return urlString;
  }

  const url = new URL(urlString);
  const queryString = url.searchParams.toString();
  if (queryString) {
    return `${url.pathname}?${url.searchParams.toString()}`;
  }
  return url.pathname;
}

export const createUrl = (pathname: string, params: URLSearchParams | ReadonlyURLSearchParams) => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? '?' : ''}${paramsString}`;

  return `${pathname}${queryString}`;
};

export const ensureStartsWith = (stringToCheck: string, startsWith: string) =>
  stringToCheck.startsWith(startsWith) ? stringToCheck : `${startsWith}${stringToCheck}`;

export const validateEnvironmentVariables = () => {
  const requiredEnvironmentVariables = [
    'BIGCOMMERCE_CANONICAL_STORE_DOMAIN',
    'BIGCOMMERCE_ACCESS_TOKEN',
    'BIGCOMMERCE_CUSTOMER_IMPERSONATION_TOKEN',
    'BIGCOMMERCE_STORE_HASH',
    'BIGCOMMERCE_CLIENT_ID',
    'BIGCOMMERCE_CLIENT_SECRET'
  ];
  const missingEnvironmentVariables = [] as string[];

  requiredEnvironmentVariables.forEach((envVar) => {
    if (!process.env[envVar]) {
      missingEnvironmentVariables.push(envVar);
    }
  });

  if (missingEnvironmentVariables.length) {
    throw new Error(
      `The following environment variables are missing. Your site will not work without them. \n\n${missingEnvironmentVariables.join(
        '\n'
      )}\n`
    );
  }
};

export const hasSalePrice = <T extends { salePrice?: Money; }>(productOrVariant: T) => productOrVariant.salePrice !== undefined && productOrVariant.salePrice.amount !== '0';
export const getSalePrice = <T extends { salePrice?: Money; basePrice: Money; }>(productOrVariant: T): Money => hasSalePrice(productOrVariant) ? productOrVariant.salePrice! : productOrVariant.basePrice;

export const isDevelopment = (baseUrl?: string) => process.env.NODE_ENV === "development" || /(?:localhost|127.0.0.1)/.test(baseUrl ?? BASE_URL);
export const isSB = (baseUrl?: string) => envId(baseUrl) === 'sb';
export const isQA = (baseUrl?: string) => envId(baseUrl) === 'qa';
export const isUAT = (baseUrl?: string) => envId(baseUrl) === 'uat';
const envId = (baseUrl?: string) => (baseUrl ?? BASE_URL).match(/ecommerce\.([a-z]*)\.4patriots\.net/)?.at(1);

export const cookieDomain = (baseUrl?: string) => {
  const hostname = baseUrl ? new URL(baseUrl).hostname.replace('www.', '') : 'localhost';
  const domain = hostname !== 'localhost' ? `.${hostname}` : undefined;
  return domain;
}

export const cookieSameSite = (baseUrl?: string, secure?: boolean) => {
  if(secure) {
    if (isDevelopment(baseUrl)) {
      return 'none';
    }
    const env = envId(baseUrl);
    if (env === 'sb' || env === 'qa') {
      return 'none';
    }
  }

  return 'lax';
}

export const cookieSecure = (baseUrl?: string) => {
  return baseUrl?.startsWith("https://");
}

export function zodErrorMessage<Input, Output>(result: SafeParseReturnType<Input, Output>) {
  if (result && !result.success) {
    return result.error.errors.at(0)?.message ?? 'Invalid Request';
  }
  return 'Invalid Request'
}

export const tryParseMoney = (money?: string) => {
  try {
    return money ? parseFloat(money) : undefined;
  }
  catch { }
};

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
