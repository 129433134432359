export const base64Encode = (plainString: string) => {
    if (typeof window === 'undefined') {
        return Buffer.from(plainString).toString('base64')
    }
    return window.btoa(plainString)
};

export const base64Decode = (encodedString: string) => {
    if (typeof window === 'undefined') {
        return Buffer.from(encodedString, 'base64').toString('utf-8');
    }
    return window.atob(encodedString);
}

export const tryParseJSON = <T>(text?: string) => {
    if (!text) {
        return null;
    }
    try {
        const val = JSON.parse(text);
        if (val && val instanceof Array || val instanceof Object) {
            return val as T;
        }
    }
    catch (_e) {
        return null;
    }
    return null;
}

export function tryParseDate(dateValue?: any) {
    if (dateValue) {
        const date = new Date(dateValue);
        if (date.getTime() > 0) {
            return date;
        }
    }
    return null;
}