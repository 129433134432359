import { base64Decode, base64Encode, tryParseJSON } from "./util";

export const builderContextSearchParam = 'builderContext';

export type WithBuilderContextProps = {
    builderContext: BuilderContextProps;
};

export interface FunnelProps {
    productLine: string;
    funnelName: string;
    lander: string;
}

export interface FunnelData extends Partial<FunnelProps> {
    stepName?: string,
    type?: string,
    brand?: string,
}

export interface BuilderContextProps {
    apiKey?: string,
    userAttributes: {
        [key: string]: undefined | string | string[] | boolean | boolean[] | number | number[] | Record<string, any>;
        productLine?: string,
        funnelName?: string,
        lander?: string,
        isFunnel: boolean
    }
}

export const defaultBuilderContext: BuilderContextProps = {
    apiKey: undefined,
    userAttributes: {
        productLine: undefined,
        funnelName: undefined,
        lander: undefined,
        isFunnel: false
    }
};

export function withBuilderContext(contextData: BuilderContextProps) {
    const {
        productLine,
        funnelName,
        lander,
        isFunnel,
        ...rest
    } = contextData.userAttributes;
    return {
        ...(productLine && { productLine }),
        ...(funnelName && { funnelName }),
        ...(lander && { lander }),
        ...(isFunnel !== undefined && { isFunnel }),
        ...rest
    };
}

export function parseBuilderContext(json: string | null): BuilderContextProps {
    const contextData = defaultBuilderContext;
    if (json) {
        const builderContextData = base64Decode(json ?? '');
        const result = tryParseJSON<BuilderContextProps>(builderContextData)
        contextData.apiKey = result?.apiKey;
        contextData.userAttributes = { ...contextData.userAttributes, ...(result?.userAttributes ? result.userAttributes : {}) }
    }
    return contextData;
}

export const getBuilderContextString = () => {
    if (typeof window !== 'undefined') {
        const builderContextParam = new URLSearchParams(window.location.search).get(builderContextSearchParam);
        if (builderContextParam) {
            return decodeURIComponent(builderContextParam);
        }
    }
    return null;
};

export const getBuilderContext = () => parseBuilderContext(getBuilderContextString());

export function serializeBuilderContext(contextData: BuilderContextProps): string {
    const json = JSON.stringify(contextData);
    return base64Encode(json);
}
