'use client'
import { VercelCartItem } from "lib/bigcommerce/types";
import { Accumulator, Mapping, Offer } from "lib/bigcommerce/types/mulberry";
import { WARRANTY_SKU } from "lib/constants";

let isInitialized = false;
let offersInitialized = '';
let cachedOffer: null = null;
const MULBERRY_PUBLIC_TOKEN = process.env.NEXT_PUBLIC_MULBERRY_PUBLIC_TOKEN

export const Init = () => {
    if (!MULBERRY_PUBLIC_TOKEN || !window.mulberry || isInitialized) return;
    isInitialized = true;
    try {
        window.mulberry.core.init({
            publicToken: MULBERRY_PUBLIC_TOKEN
        });
    } catch (err) {
        console.error('Mulberry error:', err);
        isInitialized = false; 
    }
};

export const getWarrantyOffer = (title: string, sku: string, price:string): Promise<any> => {
    if (!isInitialized) {
        return Promise.reject('Mulberry has not been initialized');
    }

    if (offersInitialized === sku && cachedOffer) {
        return Promise.resolve(cachedOffer);
    }

    return new Promise((resolve, reject) => {
        if (typeof window === "undefined") return reject('Window is not defined');
        try {
            const offer = window.mulberry.core.getWarrantyOffer({
                title: title,
                id: sku,
                price: price
            });
            offersInitialized = sku;
            cachedOffer = offer;
            resolve(offer);
        } catch (err) {
            console.error('Mulberry error:', err);
            reject(err);
        }
    });
}

export const getWarrantyOffersCartItems = (title: string, sku: string, price:string): Promise<any> => {
    if (!isInitialized) {
        return Promise.reject('Mulberry has not been initialized');
    }

    return new Promise((resolve, reject) => {
        if (typeof window === "undefined") return reject('Window is not defined');
        try {
            const offer = window.mulberry.core.getWarrantyOffer({
                title: title,
                id: sku,
                price: price
            });
            resolve(offer);
        } catch (err) {
            console.error('Mulberry error:', err);
            reject(err);
        }
    });
}

export const renderOffers = (
    offers: Offer[],
    type: string,
    index: number,
    context: 'cart' | 'mobile' | 'minicart' | 'pdp'
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (typeof window === "undefined") return reject('Window is not defined');
      if (!isInitialized) return reject('Mulberry has not been initialized');
  
      const initializeOffers = (selector: string) => {
        window.mulberry.inline.init({
          offers: offers,
          settings: window.mulberry.core.settings,
          selector: selector,
          layout: {
            input: type,
          },
          onWarrantyToggle: async (warranty: { isSelected: any; offer: any; }) => {
            window.mulberry.inline.isSelected = warranty.isSelected;
            if (warranty.isSelected) {
              window.selectedOffer = warranty.offer;
            } else {
              window.selectedOffer = null;
            }
          }
        });
      };
  
      try {
        if (context === 'cart') {
          initializeOffers(`.mulberry-inline-container-${index}`);
          initializeOffers(`.mulberry-mobile-inline-container-${index}`);
        } else if (context === 'minicart') {
          initializeOffers(`.mulberry-minicart-inline-container-${index}`);
        } else if (context === 'pdp') {
          initializeOffers(`.mulberry-inline-container-${index}`);
        }
        resolve();
      } catch (err) {
        console.error('Mulberry error:', err);
        reject(err);
      }
    });
  };

export const clearOffers = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (typeof window !== "undefined" && isInitialized)  {
        window.mulberry.inline.isSelected = false;
        window.selectedOffer = null;
      }
    });
  };

export const formatDuration = (months: string): string => {
    const monthsNumber = Number(months);
    const years = monthsNumber / 12;
    const yearString = years === 1 ? "Year" : "Years";
    return `${years} ${yearString}`;
  };

export async function mulberryAddWarrantyToCart(storeHash: string, cartId: string, offer: any, quantity: number, itemId: string) {
    const apiUrl =
    "https://mb-shim.getmulberry.com/api/bigcommerce/carts/custom-items";
    const lineItemName =
    `${formatDuration(offer.duration_months)} Mulberry Protection ${itemId}`
    const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
        store_hash: storeHash,
        cart_id: cartId,
        custom_items: [
            {
            sku: WARRANTY_SKU, // offer.warranty_offer_id
            name: lineItemName,
            list_price: offer.customer_cost,
            quantity: quantity,
            },
        ],
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to add warranty to cart');
    }

    return response
}

const extractLastItemBySpace = (title: string): string | null => {
    const parts = title.split(' ');
    return parts.length > 0 ? parts[parts.length - 1] ?? null : null;
};

export const createMapping = (activeCart: { lines: VercelCartItem[] }): Mapping[] => {
    if (!activeCart) return [];

    const products = activeCart.lines.filter((item: VercelCartItem) => item.type === 'product').reduce((acc: Accumulator, item) => {
          const sku = item.merchandise.product?.variants ? item.merchandise.sku : item.merchandise.product.sku;
          acc[sku ?? ''] = item;
          return acc;
      }, {});

    const newMapping: Mapping[] = [];

    activeCart.lines.forEach(item => {
        if (item.type === 'custom') {
        const parentSku = extractLastItemBySpace(item.merchandise.title);
        const parentProduct = products[parentSku ?? ''];
        if (parentProduct) {
            newMapping.push({ productId: parentProduct.id, customItemId: item.id });
        }
        }
    });

    return newMapping;
};

