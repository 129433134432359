/* eslint-disable no-unused-vars*/

export const BASE_URL = process.env.NEXT_PUBLIC_DOMAIN_NAME ?? 'http://localhost:3000';
export const SITE_NAME = process.env.SITE_NAME;
export const BUILD_ID = process.env.BUILD_ID!;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const NAVIDIUM_SKU = '4P_Shipping_Protection';
export const CUSTOM_ANALYTICS_URL = process.env.NEXT_PUBLIC_CUSTOM_ANALYTICS_URL;
export const BLOTOUT_URL = process.env.NEXT_PUBLIC_BLOTOUT_URL;
export const WARRANTY_SKU = 'MULBERRY-WARRANTY';

export enum BigCommerceSortKeys {
  A_TO_Z = 'A_TO_Z',
  BEST_REVIEWED = 'BEST_REVIEWED',
  BEST_SELLING = 'BEST_SELLING',
  RELEVANCE = 'RELEVANCE',
  FEATURED = 'FEATURED',
  HIGHEST_PRICE = 'HIGHEST_PRICE',
  LOWEST_PRICE = 'LOWEST_PRICE',
  NEWEST = 'NEWEST',
  Z_TO_A = 'Z_TO_A'
}

export enum VercelSortKeys {
  RELEVANCE = 'RELEVANCE',
  BEST_SELLING = 'BEST_SELLING',
  CREATED_AT = 'CREATED_AT',
  PRICE = 'PRICE'
}

export enum vercelToBigCommerceSortKeys {
  RELEVANCE = 'RELEVANCE',
  BEST_SELLING = 'BEST_SELLING',
  CREATED_AT = 'NEWEST',
  PRICE = 'LOWEST_PRICE',
  PRICE_ON_REVERSE = 'HIGHEST_PRICE'
}

export type SortFilterItem = {
  title: string;
  slug: string | null;
  sortKey: keyof typeof VercelSortKeys;
  reverse: boolean;
};

export const defaultSort: SortFilterItem = {
  title: 'Relevance',
  slug: null,
  sortKey: 'RELEVANCE',
  reverse: false
};

export const sorting: SortFilterItem[] = [
  defaultSort,
  { title: 'Trending', slug: 'trending-desc', sortKey: 'BEST_SELLING', reverse: false }, // asc
  { title: 'Latest arrivals', slug: 'latest-desc', sortKey: 'CREATED_AT', reverse: true },
  { title: 'Price: Low to high', slug: 'price-asc', sortKey: 'PRICE', reverse: false }, // asc
  { title: 'Price: High to low', slug: 'price-desc', sortKey: 'PRICE', reverse: true }
];

export const TAGS = {
  collections: 'collections',
  collection: (productId: string) => `product ${productId}`,
  products: 'products',
  product: (productId: string) => `product ${productId}`,
  productsku: (sku: string) => `product sku ${sku}`,
  cart: (cartId: string) => `cart ${cartId}`,
  customer: (customerId: string) => `customer ${customerId}`,
  route: (path: string) => `route ${path}`
};

export const FUNNEL_PRODUCT_CUSTOM_FIELD = 'Product: Funnel Product';
export const HIDDEN_PRODUCT_TAG = 'nextjs-frontend-hidden';
export const DEFAULT_OPTION = 'Default Title';
