import { Component as ComponentObj } from '@builder.io/sdk';
import React from 'react';
import './rte.css';

export function cqlRTE(
  props: {
    html: string, 
    attributes: {
      style: any, 
      className: string, 
      'builder-id': string | undefined,
      id: string
    }
  }
) {
  const sectionInner = { __html: props.html }
  return (
    <section  
      id={props.attributes.id} 
      style={props.attributes.style} 
      className={`cql-formatted-text ${props.attributes.className}`}  
      dangerouslySetInnerHTML={sectionInner}
      builder-id={props.attributes['builder-id']}
    />
  )
}

export const cqlRTEoptions: ComponentObj = {
  name: 'CQL Text',
  noWrap: true,
  image: 'https://unpkg.com/css.gg@2.0.0/icons/svg/transcript.svg',
  description: 'Custom WYSIWYG for CQL comps',
  inputs: [
    {
      name: 'html',
      friendlyName: 'Custom HTML',
      type: 'CQLText',
    }
  ]
}
