'use client'

import { Cart, CartLineItem, Product, ProductVariant } from 'lib/bigcommerce/types';
import { Customer } from 'lib/bigcommerce/types/customer';
import { getSalePrice } from 'lib/utils';
import * as deviceDetect from 'react-device-detect';
import { getBuilderContext } from 'shared-react-components';

const eventPrefix = '4p_';

export const baseGtmData = (props: { customer?: Customer, sessionId: string }) => {
  if (typeof window === 'undefined') return {};

  const { customer, sessionId } = props;

  const contextData = getBuilderContext();
  const device = getDevice();
  const baseData = {
    device: {
      name: device,
      manufacturer: deviceDetect.isMobile ? deviceDetect.mobileVendor : deviceDetect.browserName,
      type: deviceDetect.isMobile ? deviceDetect.deviceType : 'desktop',
      resolution: {
        width: window.screen.availWidth,
        height: window.screen.availHeight
      },
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      encoding: 'UTF-8',
      language: window.navigator.language,
      colors: `${window.screen.colorDepth}-bit`
    },
    browser: {
      manufacturer: device,
      name: deviceDetect.browserName,
      majorVersion: deviceDetect.browserVersion,
      version: deviceDetect.fullBrowserVersion
    },
    os: {
      majorVersion: deviceDetect.osName,
      name: deviceDetect.osName,
      version: deviceDetect.osVersion
    },
    scrollPosition: window.scrollY,
    customer: {
      id: customer?.entityId,
      email: customer?.email,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      phone: customer?.phone
    },
    sessionId,
    funnelData: {
      channel_id: contextData.userAttributes.isFunnel ? 'funnel' : 'storefront',
      productLine: contextData.userAttributes.productLine,
      funnelName: contextData.userAttributes.funnelName,
      stepName: undefined,
      type: undefined,
    }
  };
  sendGtmEvent(baseData);
  return baseData;
};

type PageViewProps = {
  prevPathname: string;
  prevSearchParams: string;
  pathname: string;
  searchParams: string;
};
export const pageView = (props: PageViewProps) => {
  if (typeof window === 'undefined') return {};

  const { prevPathname, prevSearchParams, pathname, searchParams } = props;

  const pageViewEvent = {
    event: `${eventPrefix}page_view`,
    channel_id: 'storefront',
    urlPath: pathname,
    queryParams: searchParams,
    oldUrlPath: prevPathname,
    oldQueryParams: prevSearchParams,
    scrollPosition: window.scrollY,
  };
  sendGtmEvent(pageViewEvent);
  return pageViewEvent;
};

export const viewCart = ({ cart }: { cart: Cart }) => {
  if (typeof window === 'undefined') return {};

  const couponCode = cart.coupons.at(0)?.code;

  const viewCartEvent = {
    event: `${eventPrefix}view_cart`,
    ecommerce: {
      currency: cart.cost.discountedAmount.currencyCode,
      channel_id: 'storefront',
      value: stringToFixed(cart.cost.discountedAmount.amount),
      discount: stringToFixed(cart.cost.discountAmount.amount),
      coupon: couponCode,
      items: cart.lines.map((lineItem, index) => gtmCartLineItem({ lineItem, index, couponCode })),
      customer: {
        id: cart.customerId,
        email: cart.email,
      },
      scrollPosition: window.scrollY,
      factor_13: gtmGpmTotal({ lineItems: cart.lines })?.toFixed(2),
    }
  }
  // Clear the previous ecommerce object.
  sendGtmEvent({ ecommerce: null });
  sendGtmEvent(viewCartEvent);
  return viewCartEvent;
}

type AddToCartProps = { cart: Cart, lineItem: CartLineItem, quantity: number, itemListId?: string, itemListName?: string };
export const addToCart = ({ cart, lineItem, quantity, itemListId, itemListName }: AddToCartProps) => {
  if (typeof window === 'undefined') return {};

  const couponCode = cart.coupons.at(0)?.code;
  const salePrice = tryParseMoney((lineItem.cost.salePrice ?? lineItem.cost.listPrice).amount);
  const value = salePrice ? (salePrice * quantity) : undefined;
  const gpmPrice = tryParseMoney(lineItem.cost.gpm?.amount);
  const gpm = gpmPrice ? (gpmPrice * quantity) : undefined;
  const addToCartEvent = {
    event: `${eventPrefix}add_to_cart`,
    ecommerce: {
      currency: lineItem.cost.listPrice.currencyCode,
      channel_id: 'storefront',
      value: value?.toFixed(2),
      coupon: couponCode,
      items: [gtmCartLineItem({ lineItem, index: 0, couponCode, quantity, itemListId, itemListName })],
      customer: {
        id: cart.customerId,
        email: cart.email,
      },
      scrollPosition: window.scrollY,
      factor_13: gpm?.toFixed(2),
    }
  }
  // Clear the previous ecommerce object.
  sendGtmEvent({ ecommerce: null });
  sendGtmEvent(addToCartEvent);
  return addToCartEvent;
}

export const removeFromCart = ({ cart, lineItem, quantity }: { cart: Cart, lineItem: CartLineItem, quantity: number }) => {
  if (typeof window === 'undefined') return {};

  const couponCode = cart.coupons.at(0)?.code;
  const salePrice = tryParseMoney((lineItem.cost.salePrice ?? lineItem.cost.listPrice).amount);
  const value = salePrice ? (salePrice * quantity) : undefined;
  const gpmPrice = tryParseMoney(lineItem.cost.gpm?.amount);
  const gpm = gpmPrice ? (gpmPrice * quantity) : undefined;
  const removeFromCartEvent = {
    event: `${eventPrefix}remove_from_cart`,
    ecommerce: {
      currency: lineItem.cost.listPrice.currencyCode,
      channel_id: 'storefront',
      value: value?.toFixed(2),
      coupon: couponCode,
      items: [gtmCartLineItem({ lineItem, index: 0, couponCode, quantity })],
      customer: {
        id: cart.customerId,
        email: cart.email,
      },
      scrollPosition: window.scrollY,
      factor_13: gpm?.toFixed(2),
    }
  }
  // Clear the previous ecommerce object.
  sendGtmEvent({ ecommerce: null });
  sendGtmEvent(removeFromCartEvent);
  return removeFromCartEvent;
}

export const addToWishlist = ({ product, sku, customer }: { product: Product, sku?: string, customer?: Customer }) => {
  if (typeof window === 'undefined') return {};

  const variant = product.variants.find(v => v.sku === sku) ?? product.variants.at(0);
  const salePrice = getSellingPrice(product, variant);
  const addToWishlistEvent = {
    event: `${eventPrefix}add_to_wishlist`,
    ecommerce: {
      currency: salePrice.currencyCode,
      channel_id: 'storefront',
      value: stringToFixed(salePrice.amount),
      coupon: null,
      items: [gtmViewItem({ product, index: 0, variant })],
      customer: {
        id: customer?.entityId,
        email: customer?.email,
      },
      scrollPosition: window.scrollY,
    }
  }
  // Clear the previous ecommerce object.
  sendGtmEvent({ ecommerce: null });
  sendGtmEvent(addToWishlistEvent);
  return addToWishlistEvent;
}

export const search = ({ searchTerm }: { searchTerm: string }) => {
  if (typeof window === 'undefined') return {};

  const searchEvent = {
    event: `${eventPrefix}search`,
    search_term: searchTerm
  }
  sendGtmEvent(searchEvent);
  return searchEvent;
}

export const viewItemList = ({ product, sku, itemListId, itemListName }: { product: Product, sku?: string, itemListId?: string, itemListName?: string }) => {
  if (typeof window === 'undefined') return {};

  const variant = product.variants.find(v => v.sku === sku) ?? product.variants.at(0);
  const viewItemListEvent = {
    event: `${eventPrefix}view_item_list`,
    ecommerce: {
      item_list_id: itemListId,
      item_list_name: itemListName,
      items: [gtmViewItem({ product, index: 0, variant })],
    },
    scrollPosition: window.scrollY,
  }

  sendGtmEvent({ ecommerce: null });
  sendGtmEvent(viewItemListEvent);
  return viewItemListEvent;
}

export const viewItem = ({ product, sku }: { product: Product, sku?: string }) => {
  if (typeof window === 'undefined') return {};

  const variant = product.variants.find(v => v.sku === sku) ?? product.variants.at(0);
  const salePrice = getSellingPrice(product, variant);
  const viewItemEvent = {
    event: `${eventPrefix}view_item`,
    ecommerce: {
      currency: salePrice.currencyCode,
      value: stringToFixed(salePrice.amount),
      items: [gtmViewItem({ product, index: 0, variant })],
    },
    scrollPosition: window.scrollY,
  }
  sendGtmEvent({ ecommerce: null });
  sendGtmEvent(viewItemEvent);
  return viewItemEvent;
}

type Order = {
  baseAmount: number;
  billingAddress: {
    firstName: string;
    lastName: string;
    address1: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    countryCode: string;
    email: string;
    phone: string;
  };
  coupons: Array<{
    code: string;
  }>;
  consignments: {
    shipping: {
      firstName: string;
      lastName: string;
      address1: string;
      city: string;
      stateOrProvince: string;
      postalCode: string;
      countryCode: string;
      email: string;
      phone: string;
    }
  };
  currency: {
    code: string
  }
  customerId: number;
  discountAmount: number;
  lineItems: {
    physicalItems: OrderLineItem[];
  };
  orderAmount: number;
  orderId: number;
  shippingCostTotal: number;
  taxTotal: number;
};

type PurchaseProps = {order: Order, product?: Product, itemListId?: string, itemListName?: string };
export const purchase = async ({ order, product, itemListId, itemListName }: PurchaseProps) => {
  if (typeof window === 'undefined') return;

  const lineItem = order.lineItems?.physicalItems?.at(0);
  const variant = product?.variants?.find(v => lineItem?.sku === v.sku);
  const gpm = variant?.productMetafields?.find(m => m.key === 'gpm')?.value?.toString() ?? product?.productMetafields?.find(m => m.key === 'gpm')?.value?.toString();
  const gpmPrice = tryParseMoney(gpm);
  const quantity = lineItem?.quantity ?? 1;
  const gpmTotal = gpmPrice ? (gpmPrice * quantity) : undefined;
  const couponCode = order.coupons.at(0)?.code;
  const purchaseEvent = {
    event: `${eventPrefix}purchase`,
    ecommerce: {
      currency: order.currency?.code ?? 'USD',
      channel_id: 'funnel',
      value: order.orderAmount.toFixed(2),
      transaction_id: order.orderId,
      coupon: couponCode,
      shipping: order.shippingCostTotal?.toFixed(2),
      tax: order.taxTotal?.toFixed(2),
      items: [gtmOrderLineItem({ lineItem, product, index: 0, couponCode, itemListId, itemListName })],
      customer: {
        id: order.customerId,
        email: order.billingAddress?.email
      },
      scrollPosition: window.scrollY,
      factor_13: gpmTotal?.toFixed(2),
    }
  }
  // Clear the previous ecommerce object.
  sendGtmEvent({ ecommerce: null });
  sendGtmEvent(purchaseEvent);
  return purchaseEvent;
}

// Mapper Functions
type CartLineItemProps = { lineItem: CartLineItem, index: number, couponCode?: string, quantity?: number, itemListId?: string, itemListName?: string };
const gtmCartLineItem = ({ lineItem, index, couponCode, quantity, itemListId, itemListName }: CartLineItemProps) => {
  const product = lineItem.merchandise.product;
  const variant = lineItem.merchandise.product.variants.find(v => lineItem.sku === v.sku);
  const salePrice = lineItem.cost.salePrice ?? lineItem.cost.listPrice;

  const selectedOptions = lineItem.merchandise.selectedOptions
    .map(({ name, value }) => `${name}: ${value}`)
    .join(' ');

  return {
    index,
    item_id: lineItem.sku,
    item_name: lineItem.merchandise.title,
    item_variant_id: variant?.sku,
    coupon: couponCode,
    discount: stringToFixed(lineItem.cost.discountAmount?.amount),
    item_brand: product.brand,
    item_category: product.categories.at(0)?.name,
    item_category2: product.categories.at(1)?.name,
    item_category3: product.categories.at(2)?.name,
    item_category4: product.categories.at(3)?.name,
    item_category5: product.categories.at(4)?.name,
    item_list_id: itemListId,
    item_list_name: itemListName,
    item_variant: selectedOptions,
    price: stringToFixed(salePrice.amount),
    retail: lineItem.cost.retailPrice && stringToFixed(lineItem.cost.retailPrice.amount),
    cost: lineItem.cost.costPrice && stringToFixed(lineItem.cost.costPrice.amount),
    factor_13: lineItem.cost.gpm && stringToFixed(lineItem.cost.gpm.amount),
    quantity: quantity ?? lineItem.quantity
  };
};

type OrderLineItem = {
  sku: string;
  name: string;
  quantity: number;
  brand: string;
  categoryNames?: string[];
  discountAmount: number;
  couponAmount: number;
  listPrice: number;
  salePrice: number;
  retailPrice: number;
  options?: Array<{
    name: string;
    value: string;
  }>;
};

type OrderLineItemProps = { lineItem?: OrderLineItem, product?: Product, index: number, couponCode?: string, itemListId?: string, itemListName?: string };
const gtmOrderLineItem = ({ lineItem, index, couponCode, itemListId, itemListName, product }: OrderLineItemProps) => {
  if (!lineItem) {
    return {};
  }

  const variant = product?.variants?.find(v => lineItem.sku === v.sku);
  const costPrice = variant?.productMetafields?.find(m => m.key === 'cost-price')?.value?.toString() ?? product?.productMetafields?.find(m => m.key === 'cost-price')?.value?.toString();
  const gpm = variant?.productMetafields?.find(m => m.key === 'gpm')?.value?.toString() ?? product?.productMetafields?.find(m => m.key === 'gpm')?.value?.toString();
  const selectedOptions = lineItem.options?.map(({ name, value }) => `${name}: ${value}`)?.join(' ');

  return {
    index,
    item_id: lineItem.sku,
    item_name: lineItem.name,
    item_variant_id: variant?.sku,
    coupon: couponCode,
    discount: lineItem.discountAmount?.toFixed(2),
    item_brand: lineItem.brand,
    item_category: lineItem.categoryNames?.at(0),
    item_category2: lineItem.categoryNames?.at(1),
    item_category3: lineItem.categoryNames?.at(2),
    item_category4: lineItem.categoryNames?.at(3),
    item_category5: lineItem.categoryNames?.at(4),
    item_list_id: itemListId,
    item_list_name: itemListName,
    item_variant: selectedOptions,
    price: lineItem.salePrice?.toFixed(2) ?? lineItem.listPrice.toFixed(2),
    retail: lineItem.retailPrice?.toFixed(2),
    cost: stringToFixed(costPrice),
    factor_13: stringToFixed(gpm),
    quantity: lineItem.quantity
  };
};

type ViewItemProps = { product: Product, index: number, variant?: ProductVariant, itemListId?: string, itemListName?: string }
const gtmViewItem = ({ product, index, variant, itemListId, itemListName }: ViewItemProps) => {
  const selectedOptions = variant?.selectedOptions
    .map(({ name, value }) => `${name}: ${value}`)
    .join(' ');
  const salePrice = getSellingPrice(product, variant);

  return {
    index,
    item_id: product.sku,
    item_name: product.title,
    item_variant_id: variant?.sku,
    coupon: null,
    discount: null,
    item_brand: product.brand,
    item_category: product.categories.at(0)?.name,
    item_category2: product.categories.at(1)?.name,
    item_category3: product.categories.at(2)?.name,
    item_category4: product.categories.at(3)?.name,
    item_category5: product.categories.at(4)?.name,
    // item_list_id: itemListId,
    // item_list_name: itemListName,
    item_variant: selectedOptions,
    price: stringToFixed(salePrice.amount),
    retail: variant?.retailPrice ?? product.retailPrice,
    cost: variant?.costPrice ?? product.costPrice,
    factor_13: variant?.gpm ?? product.gpm,
    quantity: 1
  };
};

// Utility Functions

const stringToFixed = (money?: string) => {
  return tryParseMoney(money)?.toFixed(2)
};

const tryParseMoney = (money?: string) => {
  try {
    return money ? parseFloat(money) : undefined;
  }
  catch { }
};

const getSellingPrice = (product: Product, variant?: ProductVariant) => {
  const variantPrice = variant && getSalePrice(variant);
  const productPrice = getSalePrice(product);
  return variantPrice ?? productPrice;
}

const gtmGpmTotal = ({ lineItems }: { lineItems: CartLineItem[] }) => {
  const total = lineItems.reduce(
    (total, lineItem) => {
      const gpmString = lineItem.cost.gpm?.amount
      const gpm = tryParseMoney(gpmString) ?? 0;
      return total + (lineItem.quantity * gpm);
    }, 0
  );
  return total;
}

export function getDevice() {
  if (deviceDetect.isAndroid) return 'Android'
  if (deviceDetect.isWinPhone) return 'Windows Phone'
  if (deviceDetect.isMobileSafari) return 'Safari'
  if (deviceDetect.isIOS) return 'iOS'
  if (deviceDetect.isChrome) return 'Chrome'
  if (deviceDetect.isFirefox) return 'Firefox browser'
  if (deviceDetect.isSafari) return 'Safari browser'
  if (deviceDetect.isOpera) return 'Opera browser'
  if (deviceDetect.isIE) return 'Internet Explorer browser'
  if (deviceDetect.isEdge) return 'Edge browser'
  if (deviceDetect.isChromium) return 'Chromium'
  if (deviceDetect.isSmartTV) return 'SmartTv'
}

function sendGtmEvent(payload: any) {
  if (typeof window === 'undefined') return;

  if (window['dataLayer']) {
    window['dataLayer'].push(payload)
  } else {
    console.warn('GA dataLayer does not exist')
  }
}
