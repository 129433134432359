import deepmerge from 'deepmerge';
import { StorageProvider } from './storage-provider';

type State = any;

export class CustomAnalytics {
    private customAnalyticsUrl?: string;
    private defaultHeaders: HeadersInit;
    static storageKey = "customAnalytics";

    constructor(customAnalyticsUrl?: string) {
        this.customAnalyticsUrl = customAnalyticsUrl;
        this.defaultHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }

    async pageEnter(payload?: Partial<State>) {
        await this.sendEvent('pageEnter', payload);
        return payload;
    }

    async pageLeave(payload?: Partial<State>) {
        await this.sendEvent('pageLeave', payload);
        return payload;
    }

    async purchase(payload?: Partial<State>) {
        await this.sendEvent('purchase', payload);
        return payload;
    }

    async sendEvent(event: string, payload?: Partial<State>) {
        const state = this.getState();
        const newState = deepmerge<State>(state, {
            ...payload,
            timestamp: new Date().toISOString(),
            event,
        });
        StorageProvider.setItem(CustomAnalytics.storageKey, newState);

        if (this.customAnalyticsUrl) {
            try {
                await fetch(this.customAnalyticsUrl, {
                    method: 'POST',
                    headers: this.defaultHeaders,
                    body: JSON.stringify(newState),
                    cache: 'no-cache',
                    mode: 'no-cors',
                    keepalive: true,
                });
            } catch (error) {
                console.error(error);
            }
        } else {
            console.warn('Custom Analytics is missing configuration: CUSTOM_ANALYTICS_URL');
        }

        return newState;
    }

    push(payload: Partial<State>) {
        const state = this.getState();
        const newState = { ...state, ...payload };
        StorageProvider.setItem(CustomAnalytics.storageKey, newState);
        return newState;
    }

    getState() {
        const storedState = StorageProvider.getItem(CustomAnalytics.storageKey);
        return storedState ?? this.initialState;
    }

    initialState: State = {};
}
