import { tryParseJSON } from "../util/util";

type StorageWrapper<T> = {
  data: T,
  expireTime: number | null
}
export class StorageProvider {

  static getItem<T>(key: string): T | null {
    if (typeof localStorage !== 'undefined') {
      const saved = localStorage.getItem(key);
      if (saved !== null) {
        const result = tryParseJSON<StorageWrapper<T>>(saved);
        if (result) {
          if (result.expireTime && result.expireTime <= Date.now()) {
            StorageProvider.removeItem(key);
            return null;
          }
          return result.data;
        }
      }
    }
    return null;
  }

  static setItem<T>(key: string, value: T, maxAge?: number | null) {
    if (typeof localStorage !== 'undefined') {
      const expireTime = maxAge !== null ? Date.now() + (maxAge ?? 1 * 60 * 60 * 1000) : null;
      const result = {
        data: value,
        expireTime: expireTime
      }
      localStorage.setItem(key, JSON.stringify(result));
    }
  }

  static removeItem(key: string) {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(key);
    }
  }
}